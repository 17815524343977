$breakpoints: (
  xs: 1,
  sm: 1,
  md: 2,
  lg: 2,
  xl: 3,
  xxl: 4,
);
$margin: 20px;

.blogTiles {
  margin-left: -$margin/2;
  margin-right: -$margin/2;
  display: flex;
  justify-content: flex-start; //center;
  flex-wrap: wrap;

  margin-top: 2rem;
  margin-bottom: 2rem;

  &__loadMore {
    margin-bottom: 2rem;
    .thinBtn {
      width: 100%;
      display: block;
      color: white;
      background-color: $font-color;
      cursor: pointer;
      &:hover,
      &:focus {
        color: $font-color;
        background-color: white;
      }
    }
  }

  &--productView .blogTile {
    @include media-breakpoint-up(xxl) {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
  }
}

.blogTile {
  padding: $margin/2;

  @each $bp, $number in $breakpoints {
    @include media-breakpoint-up(#{$bp}) {
      flex: 0 0 100% / $number;
      max-width: 100% / $number;
    }
  }

  &__link {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    background: #fff;
    color: $font-color;
    box-shadow: 1px 1px 5px 0px #e0e0e0;
    line-height: 1.1;
    text-align: left;
    padding: 8px;

    &:hover,
    &:focus {
      color: $font-color;
      text-decoration: none;

      .thinBtn {
        color: white;
        background-color: $font-color;
      }
    }
  }

  &__bg {
    @include bg-img();
    @include img-ratio(100%);
    width: 100%;
    z-index: -1;
    margin-bottom: 90px;
  }

  &__content {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &__contentInner {
    display: flex;
    flex-direction: column;
    background: rgba(#fff, 0.8);
    padding: 15px;
    margin: 30px 30px 10px 30px;
  }

  &__title {
    font-family: $family-header;
    font-weight: 400;
    font-size: 1.4rem;
    min-height: 3.1rem;
    text-transform: uppercase;
    line-height: 1;
    margin: 0.5rem 0;
    color: black;
  }

  &__desc {
    font-size: 0.8rem;
    font-style: italic;
    height: 1.5rem;
    line-height: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    display: none;
  }

  &__readMore {
  }
}

.thinBtn {
  background-color: #fff;
  border: 1px solid $font-color;
  border-radius: 0;
  font-size: 0.8rem;
  letter-spacing: 1px;
  transition: all 0.15s;
  margin: auto;
  width: 10em;
  padding: 0.5rem 1rem;
  text-align: center;
  margin-top: 2rem;
}
