@import './mixins';
@import './variables';

// plugins
@import './bootstrap/bootstrap';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
//@import '~react-day-picker/lib/style.css';
@import '~react-modal-video/scss/modal-video.scss';

//@import './fonts';
@import './base';
@import './sections';
@import './form';

// top
//@import './layout/hamburger';
//@import './layout/top';
@import './layout/top-navbar';

// bottom
@import './layout/bottomPanel';
@import './layout/bottomMenu';

@import './cms/cms-site';

// modules
@import './modules/breadcrumb';
@import './modules/rotatingBanner';
@import './modules/bannerSize';
@import './modules/pageTiles';
//@import './modules/blogTiles';
@import './modules/slider';
@import './modules/multiColumnPanel';

// components
@import '../components';

@import './site/index';

//aos
@import '~aos/src/sass/aos';

@media print {
  @import './print';
}

//banner-text-bar
section.banner-text-bar {
  .htmlPanel {
    p {
      margin-bottom: 0px;
      text-align: center;
    }
  }
}

div.check-news-letter {
  label {
    color: white;
  }
  .form-group {
    text-align: center;
  }
  .form-check-input {
    margin-top: 0.45rem;
  }
}

#arcgis-map {
  @media screen and (min-width: 992px) {
    margin: 0 50px !important;
  }
  @media screen and (min-width: 1600px) {
    margin: 0 -100px !important;
  }
  @media screen and (min-width: 2500px) {
    margin: 0 -400px !important;
  }
}
